export const navLinks = [
{
  id: "about",
  title: "生平",
},
{
  id: "gallery",
  title: "照片",
},
{
  id: "comment",
  title: "纪念",
},
];

export const detail = {
  name: "董文成",
  birth: "1940",
  death: "2023",
}

